import home from "src/assets/images/home-resize.jpg";

export function SplashPage() {
  return (
    <div className="bg-main-400 min-h-screen relative overflow-homedden flex justify-center items-center">
      <div className="px-8 flex flex-col items-center text-center text-white absolute w-full top-48">
        <h1 className="font-bold text-[100px]">Hello</h1>
        <h2 className="font-semibold text-[50px] mt-4">Welcome to Dismore</h2>
        <h4 className="text-[30px] italic mt-4">Login as Owner to Start</h4>

        <div className="slide-group-icon flex gap-2 mt-10">
          <div className="slide-icon w-8 h-2 bg-white rounded-full"></div>
          <div className="slide-icon w-2 h-2 bg-white rounded-full"></div>
          <div className="slide-icon w-2 h-2 bg-white rounded-full"></div>
          <div className="slide-icon w-2 h-2 bg-white rounded-full"></div>
        </div>
      </div>

      <div className="absolute w-[1500px] h-[1500px] -bottom-[900px] rounded-full bg-white"></div>
      <div className="bottom-0 absolute">
        <img src={home} alt="" />
      </div>
    </div>
  );
}
