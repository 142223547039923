export function MenuComponent(props) {
  return (
    <div
      key={props.id}
      className="relative w-full flex justify-center items-center"
    >
      <h4
        className="relative z-10 text-[32px] font-semibold line-clamp-1 whitespace-nowrap"
        onClick={() => {
          props.handleClick(props.id);
        }}
      >
        {props.name}
      </h4>

      {/* {props.active ? (
        <div className="bg-orange-300 w-[100px] h-[100px] absolute -left-[30px] rounded-full flex justify-center items-center">
          <div className="w-3 h-3 bg-white rounded-full mr-[20px]"></div>
        </div>
      ) : null} */}
      {props.active ? (
        <div className="w-[10px] h-full absolute left-6 flex justify-center">
          <div className="w-full h-[80%] bg-main rounded-full"></div>
        </div>
      ) : null}
    </div>
  );
}
