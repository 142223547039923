import useNetwork from "src/hooks/useNetwork";
import { MdOutlineSignalWifiStatusbarConnectedNoInternet4 } from "react-icons/md";

const limitGood = 300;
const limitSlow = 500;
const limitBad = 800;

export function ConnectionCheckComponent() {
  const networkState = useNetwork();

  const {
    online,
    since,
    downLink,
    downLinkMax,
    effectiveType,
    rtt,
    saveData,
    type,
  } = networkState;

  const style = !online
    ? "bg-red-400 text-red-800"
    : rtt > limitBad
    ? "bg-red-400 text-red-800"
    : rtt > limitSlow
    ? "bg-yellow-400 text-yellow-800"
    : "bg-green-400 text-green-800";

  const textStatus = rtt > limitBad ? "Bad" : rtt > limitSlow ? "Slow" : "Good";
  const descriptionStatus = !online
    ? "No Connection"
    : rtt > limitBad
    ? "Connection is very bad"
    : rtt > limitSlow
    ? "Connection is very slow"
    : "Connection";

  if (!online || rtt > limitBad) {
    return (
      <div
        className={`fixed bg-opacity-50 z-50 top-0 bg-black w-full h-full flex justify-center items-center`}
      >
        <div className="bg-white py-16 px-20 flex justify-center items-center flex-col rounded-3xl">
          <MdOutlineSignalWifiStatusbarConnectedNoInternet4
            className={`text-center text-red-400 text-[80px]`}
          />
          <h4 className="text-center text-lg mt-6 font-bold">
            {descriptionStatus}
          </h4>
          <p className="text-center mt-2 text-sm">
            Wait until the connection is good
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`fixed bg-opacity-50 z-50 top-4 right-4 py-1 px-5 rounded-full ${style}`}
      >
        <p className="text-xs">{textStatus}</p>
      </div>
    );
  }
}
