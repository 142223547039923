import { createContext, useState } from "react";

export const TenantContext = createContext();

export const TenantProvider = (props) => {
  const [tenants, setTenants] = useState([]);

  return (
    <TenantContext.Provider value={{ tenants, setTenants }}>
      {props.children}
    </TenantContext.Provider>
  );
};
