import { gql } from "@apollo/client";

export const GET_PRODUCTS = gql`
  query Products($submenuId: ID!) {
    products(submenuId: $submenuId) {
      id
      name
      originalPrice
      price
      stock
      description
      displayPic
    }
  }
`;
