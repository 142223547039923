import { useState } from "react";

export function ChooseTableNumberSection({ myRef, handleCancel, callback }) {
  const [tableNumber, setTableNumber] = useState("");

  return (
    <dialog ref={myRef} className="rounded-3xl shadow-x ">
      <div className="flex-col text-center py-8 px-8 flex">
        <h2 className="text-[36px] font-bold mb-2">Input your table number</h2>
        {/* <p className="text-[28px] font-medium mb-4">Ambil nomor meja mu</p> */}
        <div className="flex justify-center my-4">
          <input
            className="w-full rounded-lg bg-white outline-none border border-slate-200 p-3 text-[24px] text-center"
            placeholder="Masukkan nomor meja mu"
            type="number"
            onChange={(e) => {
              setTableNumber(e.target.value);
            }}
            value={tableNumber}
          />
        </div>

        <div className="w-full flex gap-5 mt-2">
          <button
            onClick={() => {
              handleCancel();
            }}
            className="text-[32px] w-full font-bold bg-secondary text-white hover:bg-main hover:text-white py-3 rounded-2xl shadow-xl"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              if (tableNumber >= 1) callback(tableNumber);
            }}
            className="text-[32px] w-full font-bold bg-main text-white hover:bg-secondary hover:text-white py-3 rounded-2xl shadow-xl"
          >
            Continue
          </button>
        </div>
      </div>
    </dialog>
  );
}
