export function ButtonComponent(props) {
  return (
    <button
      onClick={props.handle}
      className={`${
        props.type == "light" ? "bg-white" : "bg-main"
      } w-full text-[50px] rounded-full py-4 font-bold hover:bg-main`}
    >
      {props.title}
    </button>
  );
}

export function ButtonComponentLite(props) {
  return (
    <button
      onClick={props.handle}
      className={`${
        props.type == "light" ? "bg-white text-main" : "bg-main text-white"
      } w-full text-[20px] rounded-full px-10 py-2 font-semibold hover:bg-main`}
    >
      {props.title}
    </button>
  );
}
