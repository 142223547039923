import { BsBagCheck } from "react-icons/bs";
import { rupiahFormat } from "src/helper/rupiahFormat";

export function CardProductComponent(props) {
  return (
    <button
      onClick={() => {
        props.handleDetail(new Date().getTime());
      }}
      className="w-full h-[480px] flex relative items-end justify-center"
    >
      <div className="w-10/12 flex justify-center items-center rounded-t-3xl overflow-hidden absolute top-0 z-10">
        <img src={props.item.image} />
      </div>
      <div className="bg-slate-200 shadow-lg w-full h-[56%] flex-shrink-0 rounded-3xl flex flex-col items-center relative">
        <div className="relative top-[130px] w-9/12 mx-auto">
          <h2 className="text-[28px] line-clamp-2 leading-tight font-bold h-20">
            {props.item.name}
          </h2>

          <h2 className="text-[28px] leading-tight line-clamp-1 font-bold text-main">
            {rupiahFormat(props.item.originalPrice)}
          </h2>
        </div>
      </div>
    </button>
  );
}
