import { useContext } from "react";
import iconX from "src/assets/icon/x.svg";
import { UserContext } from "src/context/UserContext";
import { rupiahFormat } from "src/helper/rupiahFormat";

export function BillSection({
  myRef,
  handleCancel,
  tableNumber,
  typeService,
  cart,
  callback,
}) {
  const { user, setUser } = useContext(UserContext);

  return (
    <dialog ref={myRef} className="bg-white rounded-3xl shadow-xl scale-[1.5] ">
      <div className="w-[320px] flex-col py-6 px-6  flex text-neutral-900">
        <div className="flex items-center">
          <div className="grow">
            <h1 className="text-[48px] font-bold">{tableNumber}</h1>
          </div>
          <div className="text-right">
            <button
              onClick={() => {
                handleCancel();
              }}
            >
              <img src={iconX} className="w-3 ml-auto mb-4" />
            </button>
            <p className="f-p2-m">Payment by</p>
            <p className="f-p2-r ">-</p>
          </div>
        </div>
        <div className="mt-2">
          <p className="f-p1-m">Invoice: -</p>
        </div>
        <div className="mt-4 grid grid-cols-2 gap-1">
          <div className="col-span-1">
            <div className="">
              <p className="f-p2-r">Service</p>
              <p className="f-p1-m ">
                {typeService == "dine-in" ? "Dine In" : "Take Away"}
              </p>
            </div>
            <div className="mt-2">
              <p className="f-p2-r">Customer</p>
              <p className="f-p1-m line-clamp-1">{user?.phone ?? "Guest"}</p>
            </div>
          </div>
          <div className="col-span-1">
            <div className="">
              <p className="f-p2-r">Date</p>
              <p className="f-p1-m ">-</p>
            </div>
            <div className="mt-2 ">
              <p className="f-p2-r">Order Time</p>
              <p className="f-p1-m ">-</p>
            </div>
          </div>
        </div>
        <div className="border border-dashed w-full my-4 border-neutral-600"></div>
        <div className="mt-2">
          <div className="overflow-scroll h-[160px] p-4 bg-neutral-100">
            <h4 className="f-h4">Order Details</h4>
            {cart.map((item, index) => {
              return (
                <div className="list-group mt-2" key={`item_${index}`}>
                  <div className="grid grid-cols-12">
                    <div className="col-span-8">
                      <h5 className="f-h5">{item.name}</h5>
                    </div>
                    <div className="col-span-4">
                      <p className="f-p2-r text-right">x{item.amount}</p>
                    </div>
                  </div>
                  <div className="grid grid-cols-12">
                    <div className="col-span-8">
                      <p className="f-p2-r">{item.notes}</p>
                    </div>
                    <div className="col-span-4">
                      <p className="f-p2-r text-right">
                        {rupiahFormat(item.originalPrice * item.amount)}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="border border-dashed w-full my-4 border-neutral-600"></div>
        <div className="mt-2">
          <div className="flex justify-between items-center">
            <p className="f-p2-r">Sub Total</p>
            <p className="f-p2-r">{` ${rupiahFormat(
              cart.reduce(
                (temp, x) =>
                  x.buy == true ? temp + x.originalPrice * x.amount : temp,
                0,
                0
              )
            )} `}</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="f-p2-r">Discount</p>
            <p className="f-p2-r">{rupiahFormat(0)}</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="f-p2-r">Tax 10%</p>
            <p className="f-p2-r">{` ${rupiahFormat(
              cart.reduce(
                (temp, x) => (x.buy == true ? temp + x.price * x.amount : temp),
                0,
                0
              ) -
                cart.reduce(
                  (temp, x) =>
                    x.buy == true ? temp + x.originalPrice * x.amount : temp,
                  0,
                  0
                )
            )} `}</p>
          </div>
        </div>
        <div className="border border-dashed w-full my-4 border-neutral-600"></div>
        <div className="mt-2 flex items-center justify-between">
          <h3 className="f-h4">Total</h3>
          <h3 className="f-h4">{` ${rupiahFormat(
            cart.reduce(
              (temp, x) => (x.buy == true ? temp + x.price * x.amount : temp),
              0,
              0
            )
          )} `}</h3>
        </div>
        <div className="mt-4">
          <button
            onClick={() => {
              callback();
            }}
            className="text-[18px] w-full font-bold bg-main text-white hover:bg-secondary hover:text-white py-2 rounded-xl shadow-xl"
          >
            Payment
          </button>
        </div>
      </div>
    </dialog>
  );
}
