import { useNavigate } from "react-router-dom";
import home from "src/assets/images/home-resize.jpg";
import { FiChevronRight, FiRefreshCcw } from "react-icons/fi";
import { useContext, useEffect, useState } from "react";
import logoWhite from "src/assets/images/logo-white.png";
import dismore from "src/assets/images/dismore.svg";
import logoTitle from "src/assets/images/logo-title.svg";
import { CartContext } from "src/context/CartContext";
import { REACT_APP_VERSION } from "src/configs/Version";
import { MarqueComponent } from "src/ui/components/marque";
import { NotionComponent } from "src/ui/components/notion";

export function HomePage() {
  const navigate = useNavigate();

  const { cart, setCart } = useContext(CartContext);

  useEffect(() => {
    setCart([]);
  }, []);

  return (
    <div className="h-full relative overflow-hidden flex flex-col justify-center">
      <div className="absolute h-full w-full z-0">
        <img src={home} alt="" className="w-full h-full" />
      </div>
      <div className="h-3/4 w-full relative text-white flex flex-col justify-center">
        <div className="px-[100px] flex items-center gap-4">
          <img src={logoWhite} className="h-[164px]" />
          <img src={dismore} className="h-[200px]" />
        </div>

        <div className="mb-[150px] bg-opacity-50 w-full px-32 py-7 mt-10">
          <h2 className="font-semibold text-[80px]">
            Welcome to <br></br>
            <img src={logoTitle} className="w-[800px] mt-3" />
          </h2>
          <h4 className="text-[50px] font-semibold italic mt-4">
            #tempatngopicozy
          </h4>
          <p className="text-[36px] font-semibold mt-2">
            v {REACT_APP_VERSION}
          </p>

          {/* <button
            onClick={() => {
              throw new Error("My first Sentry error!");
            }}
          >
            Break the world test
          </button> */}
        </div>
      </div>

      <div className="relative w-full grow">
        <div className="grid grid-cols-12">
          <div className="col-span-5 flex flex-col items-center">
            <button
              onClick={() => {
                window.location.reload();
              }}
              className="rounded-full bg-main p-10 text-center mb-5 shadow-xl text-white"
            >
              <FiRefreshCcw size={55} />
            </button>
            <h4 className="text-[28px] text-center text-white font-medium">
              Refresh <br /> Application
            </h4>
          </div>
          <div className="col-span-7 flex flex-col items-end">
            <button
              onClick={() => {
                navigate("/type-service");
              }}
              className=" py-8 pl-14 pr-5 w-full mb-10 text-[44px] rounded-l-full bg-main text-white font-bold flex justify-between items-center"
            >
              Order Here
              <div>
                <FiChevronRight />
              </div>
            </button>

            <button className=" py-5 pl-14 pr-5 text-[40px] font-medium italic underline text-white">
              Call for assistance
            </button>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 w-full z-10">
        <NotionComponent />
      </div>
    </div>
  );
}
