import { useNavigate, useParams } from "react-router-dom";
import typeServiceImage from "src/assets/images/enjoy.jpg";
import takeAway from "src/assets/icon/take-away.svg";
import dineIn from "src/assets/icon/dine-in.svg";
import { MarqueComponent } from "src/ui/components/marque";
import { FiArrowLeft, FiHome } from "react-icons/fi";
import { NavbarComponent } from "src/ui/components/navbar/navbar-component";
import { NotionComponent } from "src/ui/components/notion";

export function TypeServicePage() {
  const navigate = useNavigate();

  return (
    <div className="h-full relative overflow-hidden">
      <div className="fixed h-full w-full z-0 overflow-hidden">
        <img src={typeServiceImage} alt="" className="w-full h-full" />
      </div>
      <div className="fixed h-full w-full bg-gray-300 bg-opacity-70 z-0"></div>

      <div className="absolute z-10 w-full">
        <NavbarComponent
          title="Type Service"
          type="dark"
          leftIcon={FiArrowLeft}
          handleLeft={() => {
            navigate(-1);
          }}
          rightIcon={FiHome}
          handleRight={() => {
            navigate("/");
          }}
        />
      </div>

      <div className="relative h-full w-full flex flex-col items-center justify-center">
        <div className="w-10/12 flex flex-col justify-center relative">
          <div className="flex justify-center absolute w-full -top-72">
            <h2 className="text-[72px] text-center text-black font-bold">
              {" "}
              How you enjoy<hr></hr> your meal?
            </h2>
          </div>
          <div className="flex mt-16 gap-20">
            <div className="w-full">
              <button
                onClick={() => {
                  navigate("/dine-in/menu");
                }}
                className={`flex flex-col gap-5 text-[55px] rounded-[36px] font-bold justify-center items-center py-10 px-10 w-full h-[370px] hover:bg-white bg-main`}
              >
                <img src={dineIn} className="w-2/3" />
              </button>
              <h2 className="text-[56px] font-bold text-black text-center mt-5">
                DINE IN
              </h2>
            </div>
            <div className="w-full">
              <button
                onClick={() => {
                  navigate("/take-away/menu");
                }}
                className={`flex flex-col gap-5 text-[55px] rounded-[36px] font-bold justify-center items-center py-10 px-10 w-full h-[370px] hover:bg-white bg-main`}
              >
                <img src={takeAway} className="w-2/3" />
              </button>
              <h2 className="text-[56px] font-bold text-black text-center mt-5">
                TAKE AWAY
              </h2>
            </div>
          </div>
        </div>
        {/* <div className="absolute bottom-[-150px] flex gap-0">
          <img src={imageMaskot} className="h-[750px]" />
        </div> */}
      </div>

      <div className="absolute bottom-0 w-full">
        <NotionComponent />
      </div>
    </div>
  );
}
