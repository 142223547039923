import React from "react";
import { CartProvider } from "./CartContext";

import { CombineComponents } from "./CombineComponents";
import { LoadingProvider } from "./LoadingContext";
import { TenantProvider } from "./TenantContext";
import { SidebarProvider } from "./SidebarContext";
import { TokenProvider } from "./TokenContext";
import { UserProvider } from "./UserContext";
import { AdvertisePopUpProvider } from "./AdvertisePopUpContext";

const providers = [
  UserProvider,
  LoadingProvider,
  TokenProvider,
  TenantProvider,
  CartProvider,
  SidebarProvider,
  AdvertisePopUpProvider,
];
export const AppContextProvider = CombineComponents(...providers);
