import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CartContext } from "src/context/CartContext";
import imageLogo from "src/assets/images/logo.png";
import { UserContext } from "src/context/UserContext";

export function PrintPage() {
  const navigate = useNavigate();
  const { idOrderCustomer, typeService, tableNumber } = useParams();

  const { cart, setCart } = useContext(CartContext);
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    if (cart.length == 0) navigate("/");

    const execute = setTimeout(() => {
      setTimeout(() => {
        if (user?.phone != "" || true) window.print();

        setTimeout(() => {
          window.print();
          navigate(`/rating/${idOrderCustomer}`);
        }, 100);
      }, 200);
    }, 1);
    return () => clearTimeout(execute);
  }, []);

  return (
    <div className="w-full flex-col py-4 px-6 flex text-neutral-900 text-[10px]">
      <div className="text-center">
        <img src={imageLogo} className="w-[96px] mx-auto" />
        <h1 className="text-[16px] font-semibold mt-0">
          The Rooster Coffee Company
        </h1>
        <p>
          Jl. Yos Sudarso No.21, Ketel, Sidokumpul
          <br />
          Kec. Sidoarjo, Kab. Sidoarjo, Jawa Timur 61219
          <br />
          IG. @roostercoffee.id, M. 0857 8888 0857
        </p>
      </div>
      <div className="flex items-center mt-2">
        <div className="grow">
          <h1 className="text-[24px] font-bold">{tableNumber ?? "-"}</h1>
        </div>
        <div className="text-right">
          <p className="">Payment by</p>
          <p className="">QRIS</p>
        </div>
      </div>
      <div className="mt-0">
        <p className="">Invoice: {idOrderCustomer ?? "-"}</p>
      </div>
      <div className="mt-2 grid grid-cols-2 gap-1">
        <div className="col-span-1">
          <div className="">
            <p className="">Service</p>
            <p className="">
              {typeService == "dine-in" ? "Dine In" : "Take Away"}
            </p>
          </div>
          <div className="mt-2">
            <p className="">Customer</p>
            <p className="line-clamp-1">{user?.phone ?? "Guest"}</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="">
            <p className="">Order Time</p>
            <p className="">
              {new Date().toLocaleDateString()}{" "}
              {new Date().toLocaleTimeString()}
            </p>
          </div>
          <div className="mt-2 ">
            <p className="">Collected By</p>
            <p className="">Self Service | Rooster</p>
          </div>
        </div>
      </div>
      <div className="border border-dashed w-full my-2 border-neutral-600"></div>
      <div className="mt-0">
        <div className="p-0">
          <h4 className="text-[12px]">Order Details</h4>
          {cart.map((item, index) => {
            return (
              <div className="list-group mt-1" key={`item_${index}`}>
                <div className="grid grid-cols-12">
                  <div className="col-span-8">
                    <h5 className="">{item.name}</h5>
                  </div>
                  <div className="col-span-4 text-right">
                    <p className="">x{item.amount}</p>
                  </div>
                </div>
                <div className="grid grid-cols-12">
                  <div className="col-span-12">
                    <p className="italic">{item.notes}</p>
                  </div>
                </div>
                <div className="grid grid-cols-12">
                  <div className="col-span-8">
                    <p className="">IDR {item.originalPrice}</p>
                  </div>
                  <div className="col-span-4 text-right">
                    <p className="">IDR {item.originalPrice * item.amount}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="border border-dashed w-full my-2 border-neutral-600"></div>
      <div className="mt-0">
        <div className="flex justify-between items-center">
          <p className="">Sub Total</p>
          <p className="">{` IDR ${cart.reduce(
            (temp, x) =>
              x.buy == true ? temp + x.originalPrice * x.amount : temp,
            0,
            0
          )} `}</p>
        </div>
        <div className="flex justify-between items-center">
          <p className="">Discount</p>
          <p className="">IDR 0</p>
        </div>
        <div className="flex justify-between items-center">
          <p className="">Tax 10%</p>
          <p className="">{` IDR ${cart.reduce(
            (temp, x) =>
              x.buy == true
                ? temp + (x.originalPrice * x.amount * 10) / 100
                : temp,
            0,
            0
          )} `}</p>
        </div>
      </div>
      <div className="border border-dashed w-full my-2 border-neutral-600"></div>
      <div className="mt-0 flex items-center justify-between">
        <h3 className="text-[12px]">Total</h3>
        <h3 className="text-[12px]">{` IDR ${cart.reduce(
          (temp, x) => (x.buy == true ? temp + x.price * x.amount : temp),
          0,
          0
        )} `}</h3>
      </div>
      <div className="text-center mt-2">
        <h1 className="text-[16px] font-bold">Thank you</h1>
      </div>
    </div>
  );
}
