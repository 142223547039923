import { useState } from "react";
import { PhoneNumberUtil } from "google-libphonenumber";

export function PhoneSection({ myRef, callback }) {
  const [phoneNumber, setPhoneNumber] = useState("");

  return (
    <dialog ref={myRef} className="rounded-3xl shadow-x ">
      <div className="flex-col text-center py-8 px-8 flex">
        <h2 className="text-[36px] font-bold mb-2">
          Are you <span className="text-main">Rooster</span> member?
        </h2>
        <div className="flex justify-center my-4">
          <input
            className="w-full rounded-lg bg-white outline-none border border-slate-200 p-3 text-[24px] text-center"
            placeholder="Input your phone number"
            type="tel"
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
            value={phoneNumber}
          />
        </div>

        <div className="w-full flex gap-5 mt-2">
          <button
            onClick={() => {
              callback(null);
            }}
            className="text-[24px] w-full font-bold bg-secondary text-white hover:bg-main hover:text-white py-3 rounded-2xl shadow-xl"
          >
            Not Member
          </button>
          <button
            onClick={() => {
              if (phoneNumber.length >= 1) {
                const phoneUtil = PhoneNumberUtil.getInstance();
                try {
                  const number = phoneUtil.parseAndKeepRawInput(
                    phoneNumber,
                    "ID"
                  );

                  if (phoneUtil.isValidNumber(number)) {
                    callback(phoneNumber);
                  } else {
                    alert(
                      "Invalid phone number, please input your phone number correctly"
                    );
                  }
                } catch (error) {
                  alert(
                    "Invalid phone number, please input your phone number correctly"
                  );
                }
              }
            }}
            className="text-[24px] w-full font-bold bg-main text-white hover:bg-secondary hover:text-white py-3 rounded-2xl shadow-xl"
          >
            Continue
          </button>
        </div>
      </div>
    </dialog>
  );
}
