import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CgMenuRight, CgMenuLeft } from "react-icons/cg";
import { FiHome, FiSearch } from "react-icons/fi";
import logo from "src/assets/images/logo.png";
import { MdOutlineDiscount } from "react-icons/md";
import { BsBagCheck } from "react-icons/bs";

import { CartContext } from "src/context/CartContext";
import { SidebarContext } from "src/context/SidebarContext";
import { CardProductComponent } from "./component/card-product-component";
import { GET_TENANTS } from "src/graphql/query/Tenant";
import { GET_SUBMENUS } from "src/graphql/query/Submenu";
import { LoadingComponent } from "src/ui/components/loading/loading-component";
import { ErrorComponent } from "src/ui/components/error/error-component";
import { useQuery } from "@apollo/client";
import { MenuComponent } from "./component/menu-component";
import { GET_PRODUCTS } from "src/graphql/query/Product";
import { PopUpProductComponent } from "./component/PopUpProductComponent";
import { ErrorServices } from "src/service/ErrorServices";
import { rupiahFormat } from "src/helper/rupiahFormat";

export function MenuPage() {
  const navigate = useNavigate();
  const { typeService } = useParams();

  const errorServices = new ErrorServices();

  const { cart, setCart } = useContext(CartContext);
  const { sidebar, setSidebar } = useContext(SidebarContext);

  const {
    loading: loadingTenants,
    error: errorTenants,
    data: dataTenants,
    // refetch: refetchTenants,
  } = useQuery(GET_TENANTS);

  const [menuActive, setMenuActive] = useState(
    dataTenants?.tenants[0]?.menus[0]?.id
  );

  const {
    loading: loadingSubmenus,
    error: errorSubmenus,
    data: dataSubmenus,
    refetch: refetchSubmenus,
  } = useQuery(GET_SUBMENUS, { variables: { menuId: menuActive } });

  const [subMenuActive, setSubMenuActive] = useState(
    dataSubmenus?.submenus[0]?.id
  );

  const {
    loading,
    error,
    data: dataProducts,
  } = useQuery(GET_PRODUCTS, { variables: { submenuId: subMenuActive } });

  const [productActive, setProductActive] = useState(null);

  useEffect(() => {
    if (typeService == "dine-in" || true) {
      setMenuActive(dataTenants?.tenants[0]?.menus[0]?.id);
    } else {
      setMenuActive("popular");
    }
  }, [dataTenants]);

  useEffect(() => {
    setSubMenuActive(dataSubmenus?.submenus[0]?.id);
  }, [dataSubmenus]);

  if (loadingTenants) return <LoadingComponent />;
  if (errorTenants) {
    errorServices.Create({
      message: errorTenants.message,
    });
    return <ErrorComponent />;
  }

  return (
    <div className="w-full h-screen grid grid-cols-12">
      <div
        className={`bg-black duration-300 flex flex-col h-screen ${
          sidebar ? "col-span-2" : "hidden"
        }`}
      >
        <div className="flex justify-center items-center mt-20">
          <button
            className="text-white"
            onClick={() => {
              setSidebar(false);
            }}
          >
            <CgMenuRight size={72} />
          </button>
        </div>

        <div
          className="flex w-full h-fit overflow-y-scroll items-center gap-28 mt-24 text-[24px] font-semibold text-white rotate-180"
          style={{
            writingMode: "vertical-rl",
          }}
        >
          {dataTenants.tenants.toReversed().map((tenant, index) => {
            return tenant.menus.toReversed().map((menu, i) => {
              if (
                menu.isPromotion == false ||
                (typeService == "dine-in" && menu.isPromotion == true)
              )
                return (
                  <MenuComponent
                    key={menu.id}
                    id={menu.id}
                    active={menuActive == menu.id}
                    name={menu.name}
                    handleClick={(id) => {
                      setMenuActive(id);
                    }}
                  />
                );
            });
          })}
        </div>

        <div className="mt-auto flex justify-center items-center my-10 py-10">
          <button className="text-white">
            <MdOutlineDiscount size={72} />
          </button>
        </div>
      </div>

      <div
        className={`flex flex-col items-center h-screen ${
          sidebar ? "col-span-10" : "col-span-12"
        }`}
      >
        <div className="heading w-full shadow-lg px-10 pb-10">
          <div className="flex justify-between mt-20 w-full">
            {sidebar ? null : (
              <button
                onClick={() => {
                  setSidebar(true);
                }}
              >
                <CgMenuLeft size={72} />
              </button>
            )}
            <img src={logo} className="w-[180px]" />
            <button
              className=""
              onClick={() => {
                window.location.href = "/";
              }}
            >
              <FiHome size={72} />
            </button>
          </div>

          <div className="w-full mt-5">
            <h2 className="font-bold text-[48px]">
              <span className="text-main">Find your favourite foods</span>
              {/* <span></span> */}
            </h2>
          </div>

          <div className="w-full mt-8">
            <div className="flex gap-16 overflow-x-scroll">
              {dataSubmenus &&
                dataSubmenus.submenus.map((submenu, index) => {
                  return (
                    <div
                      key={submenu.id}
                      className="flex flex-col items-center"
                      onClick={() => {
                        setSubMenuActive(submenu.id);
                      }}
                    >
                      <h4 className="font-bold text-[28px] text-center leading-tight">
                        {submenu.name}
                      </h4>
                      <div
                        className={`h-1.5 bg-main mt-2 duration-300 ${
                          subMenuActive == submenu.id ? "w-16" : "w-0"
                        }`}
                      ></div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        <div className="body-product grow overflow-scroll px-10 w-full pb-10">
          {productActive != null ? (
            <PopUpProductComponent
              product={productActive}
              handleClose={() => {
                setProductActive(null);
              }}
              callback={({ count, notes }) => {
                const data = {
                  ...productActive,
                  amount: count,
                  notes: notes,
                  menuId: menuActive,
                  buy: true,
                };
                setCart([...cart, data]);
                setProductActive(null);
              }}
            />
          ) : null}

          {loading ? (
            <div className="h-fit mt-10">
              <LoadingComponent />{" "}
            </div>
          ) : (
            <div
              className={`w-full mt-10 overflow-hidden relative grid gap-8 ${
                sidebar ? "grid-cols-2" : "grid-cols-3"
              }`}
            >
              {dataProducts?.products.map((product, index) => {
                return (
                  <CardProductComponent
                    key={product.id}
                    item={{
                      id: product.id,
                      timeForId: new Date().getTime(),
                      image: product.displayPic,
                      name: product.name,
                      desc: product.desc,
                      price: product.price,
                      originalPrice: product.originalPrice,
                    }}
                    handleDetail={(timeForId) => {
                      setProductActive({ ...product, timeForId: timeForId });
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>

        <div className="w-full bg-white shadow-[0_35px_60px_15px_rgba(0,0,0,0.2)] py-8 px-8 flex justify-center">
          <button
            onClick={() => {
              navigate(`/${typeService}/order-list`);
            }}
            className="flex items-center gap-5 bg-black p-5 rounded-2xl w-full"
          >
            <div className="bg-main w-16 h-16 flex justify-center items-center rounded-lg">
              <BsBagCheck size={32} />
            </div>
            <h2 className="text-[35px] text-white">
              {cart.length == 0
                ? "Your cart is empty"
                : `
              Total ${rupiahFormat(
                cart.reduce(
                  (temp, x) => temp + x.originalPrice * x.amount,
                  0,
                  0
                )
              )} 
                | Item: ${cart.reduce((temp, x) => temp + x.amount, 0)}`}
            </h2>
          </button>
        </div>
      </div>
    </div>
  );
}
