import { createContext, useRef, useState } from "react";

export const AdvertisePopUpContext = createContext();

export const AdvertisePopUpProvider = (props) => {
  const [showAdvertisePopUp, setShowAdvertisePopUp] = useState(false);

  return (
    <AdvertisePopUpContext.Provider
      value={{ showAdvertisePopUp, setShowAdvertisePopUp }}
    >
      {props.children}
    </AdvertisePopUpContext.Provider>
  );
};
