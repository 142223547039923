import { useNavigate } from "react-router-dom";

export function PopUpCloseSection({ myRef, callback }) {
  const navigate = useNavigate();

  return (
    <dialog ref={myRef} className="bg-transparent">
      <div className="flex-col text-center py-8 px-8 bg-white rounded-3xl overflow-hidden shadow-xl flex">
        <h2 className="text-[40px] font-bold mb-2">Are you sure?</h2>
        <p className="text-[28px] font-medium mb-4">
          if you back home, all of your order list will be lost
        </p>
        <div className="flex justify-center my-4"></div>

        <div className="w-full flex gap-5">
          <button
            onClick={() => {
              window.location.href = "/";
            }}
            className="text-[36px] w-full font-bold bg-secondary text-white hover:bg-main hover:text-white py-4 rounded-2xl shadow-xl"
          >
            Yes
          </button>
          <button
            onClick={() => {
              callback();
            }}
            className="text-[36px] w-full font-bold bg-main text-white hover:bg-secondary hover:text-white py-4 rounded-2xl shadow-xl"
          >
            No
          </button>
        </div>
      </div>
    </dialog>
  );
}
