import * as Sentry from "@sentry/react";

export const initSentry = () => {
  Sentry.init({
    dsn: "https://0a26b493b7afbba0e3479b6788878dc9@o4506456989630464.ingest.sentry.io/4506459055718400",
    // dsn: "https://cea48a08b6b34da35063892141242084@o4506500372430848.ingest.sentry.io/4506501520949248",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          "http://127.0.0.1:5500",
          "https://rooster-v2.vercel.app",
          /^https:\/\/[a-zA-Z0-9.-]+\dismore\.tech/,
        ],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};
