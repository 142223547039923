import { useContext, useEffect, useRef, useState } from "react";
import { FiArrowLeft, FiHome, FiMinus, FiPlus, FiTrash } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { CartContext } from "src/context/CartContext";
import { ButtonComponentLite } from "src/ui/components/button/button-component";
import { MarqueComponent } from "src/ui/components/marque";
import { PaymentSection } from "./section/PaymentSection";
import { NavbarSecondaryComponent } from "src/ui/components/navbar/navbar-component";
import { PopUpCloseSection } from "./section/PopUpCloseSection";
import { PaymentSuccessSection } from "./section/PaymentSuccessSection";
import { GET_TENANTS } from "src/graphql/query/Tenant";
import { useMutation, useQuery } from "@apollo/client";
import { MenuComponent } from "./component/MenuComponent";
import { ChooseTableNumberSection } from "./section/ChooseTableNumberSection";
import { BillSection } from "./section/BillSection";
import { UPDATE_TABLE_NUMBER } from "src/graphql/mutation/UpdateTableNumber";
import { EmptyComponent } from "./component/EmptyComponent";
import { LoadingComponent } from "src/ui/components/loading/loading-component";
import { ErrorComponent } from "src/ui/components/error/error-component";
import { PhoneSection } from "./section/PhoneSection";
import { UserContext } from "src/context/UserContext";
import { NotionComponent } from "src/ui/components/notion";
import { PrinterServices } from "src/service/PrinterServices";
import { rupiahFormat } from "src/helper/rupiahFormat";

export function OrderListPage() {
  const navigate = useNavigate();
  const { typeService } = useParams();
  const printerServices = new PrinterServices();

  const { cart, setCart } = useContext(CartContext);
  const { user, setUser } = useContext(UserContext);

  const refClose = useRef(null);
  const refPhone = useRef(null);
  const refTableNumber = useRef(null);
  const refBill = useRef(null);
  const refPayment = useRef(null);
  const refPaymentSuccess = useRef(null);

  const [tableNumber, setTableNumber] = useState(null);

  const {
    loading: loadingTenants,
    error: errorTenants,
    data: dataTenants,
    refetch: refetchTenants,
  } = useQuery(GET_TENANTS);

  const [
    editTableNumber,
    {
      loading: loadingEditTableNumber,
      error: errorEditTableNumber,
      data: dataEditTableNumber,
    },
  ] = useMutation(UPDATE_TABLE_NUMBER);

  const updateTableNumber = async (resOrder) => {
    const res = await editTableNumber({
      variables: {
        input: {
          orderCustomerId: resOrder.id,
          tableNumber: tableNumber,
        },
      },
    });

    if (loadingEditTableNumber == false && errorEditTableNumber == undefined) {
      return true;
    }
  };

  const handlePrint = async ({ orderId }) => {
    const subTotal = cart.reduce(
      (temp, x) => (x.buy == true ? temp + x.originalPrice * x.amount : temp),
      0,
      0
    );
    const time = new Date().toLocaleString("id-ID", {
      timeZone: "Asia/Jakarta",
    });

    const repeatOrderUrl =
      "https://qr-rooster.dismore.tech/" + `${tableNumber}`;

    const tax = subTotal * 0.1;
    const json = {
      printing: ["DISMORE_SELF", "DISMORE_KITCHEN", "DISMORE_KITCHEN"],
      tableNumber: tableNumber,
      idOrderCustomer: orderId,
      typeService: typeService,
      user: user,
      orderTime: time,
      machine: "Self Service | Rooster",
      orders: cart.map((x) => {
        if (x.buy == true)
          return {
            name: x.name,
            amount: x.amount,
            notes: x.notes,
            originalPrice: rupiahFormat(x.originalPrice),
            totalPrice: rupiahFormat(x.originalPrice * x.amount),
          };
      }),
      subTotal: rupiahFormat(subTotal),
      discount: rupiahFormat(0),
      tax: rupiahFormat(tax),
      total: rupiahFormat(subTotal + tax),
      repeatOrderUrl: repeatOrderUrl,
    };

    const res = await printerServices.submitPrintJob({ json, id: orderId });
    // navigate(`/rating/${orderId}`);
  };

  if (loadingTenants) return <LoadingComponent />;
  if (errorTenants) return <ErrorComponent />;

  return (
    <div className="h-full relative overflow-hidden flex flex-col items-center">
      <PopUpCloseSection
        myRef={refClose}
        callback={() => {
          refClose.current?.close();
        }}
      />

      <PhoneSection
        myRef={refPhone}
        callback={async (memberID) => {
          setUser({ phone: memberID });
          refPhone.current?.close();
          refTableNumber.current?.showModal();
        }}
      />

      <ChooseTableNumberSection
        myRef={refTableNumber}
        callback={(value) => {
          setTableNumber(value);

          const tempCart = cart.filter((x) => x.buy == true);
          const tempCart2 = [];
          {
            dataTenants.tenants.map((tenant, index) => {
              return tenant.menus.map((menu, i) => {
                if (tempCart.filter((x) => x.menuId == menu.id).length > 0) {
                  tempCart2.push(
                    ...tempCart.filter((x) => x.menuId == menu.id)
                  );
                }
              });
            });
          }

          setCart(tempCart2);
          refTableNumber.current?.close();
          refBill.current?.showModal();
        }}
        handleCancel={() => {
          refTableNumber.current?.close();
        }}
      />

      <BillSection
        myRef={refBill}
        tableNumber={tableNumber}
        typeService={typeService}
        cart={cart}
        callback={() => {
          refBill.current?.close();
          refPayment.current?.showModal();
        }}
        handleCancel={() => {
          refBill.current?.close();
        }}
      />

      <PaymentSection
        tableNumber={tableNumber}
        myRef={refPayment}
        typeService={typeService}
        cart={cart}
        handlePrint={({ orderId }) => {
          handlePrint({ orderId });
        }}
        action={async (resOrder) => {
          const res = await updateTableNumber(resOrder);
          if (res) {
            refPayment.current?.close();
            refPaymentSuccess.current?.showModal();

            navigate(`/rating/${resOrder.id}`);

            // handlePrint({ orderId: resOrder.id });
          }
        }}
        handleCancel={() => {
          refPayment.current?.close();
        }}
      />

      <PaymentSuccessSection myRef={refPaymentSuccess} />

      <div className="w-11/12 flex justify-center">
        <NavbarSecondaryComponent
          title="Order List"
          type="dark"
          leftIcon={FiArrowLeft}
          handleLeft={() => {
            navigate(-1);
          }}
          rightIcon={FiHome}
          handleRight={() => {
            refClose.current?.showModal();
          }}
        />
      </div>

      <div className="w-full bg-gray-200 grow flex flex-col gap-2 overflow-scroll">
        {cart.length == 0 ? <EmptyComponent /> : null}
        {dataTenants.tenants.map((tenant, index) => {
          return tenant.menus.map((menu, i) => {
            if (cart.filter((x) => x.menuId == menu.id).length > 0) {
              return <MenuComponent menu={menu} key={`menu_${menu.id}`} />;
            }
          });
        })}
        <div className="bg-slate-200 h-0.5 w-full"></div>
      </div>

      <div className="w-full bg-white flex items-center justify-between px-[75px] py-[35px]">
        <div className="">
          <p className="font-bold text-[24px]">All Items</p>
        </div>
        <div className="flex items-center gap-4">
          <p className=" text-[20px]">Total</p>
          <div className="mr-3">
            <h2 className="font-extrabold text-[35px] text-main whitespace-nowrap">
              {`${rupiahFormat(
                cart.reduce(
                  (temp, x) =>
                    x.buy == true ? temp + x.originalPrice * x.amount : temp,
                  0,
                  0
                )
              )} `}
            </h2>
          </div>
          <ButtonComponentLite
            title="Continue"
            handle={() => {
              if (cart.length > 0) refPhone.current?.showModal();
              // if (cart.length > 0) refTableNumber.current?.showModal();
            }}
          />
        </div>
      </div>

      <NotionComponent />
    </div>
  );
}
