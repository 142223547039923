import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Page404 } from "src/ui/pages/error/404";
import { HomePage } from "src/ui/pages/home";
import { TypeServicePage } from "src/ui/pages/type-service";
import { MenuPage } from "src/ui/pages/menu";
import { OrderListPage } from "src/ui/pages/order-list";
import { PrintPage } from "src/ui/pages/print/PrintPage";
import { RatingPage } from "src/ui/pages/rating";

export default function GeneralRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />

        <Route path="/type-service" element={<TypeServicePage />} />

        <Route path="/:typeService/menu" element={<MenuPage />} />

        <Route path="/:typeService/order-list" element={<OrderListPage />} />

        <Route
          path="/:typeService/print/:idOrderCustomer/:tableNumber"
          element={<PrintPage />}
        />

        <Route path="/rating/:idOrderCustomer" element={<RatingPage />} />

        <Route path="/404" element={<Page404 />} />
        <Route path="*" element={<HomePage />} />
      </Routes>
    </BrowserRouter>
  );
}
