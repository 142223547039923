import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_ORDER } from "src/graphql/mutation/CreateOrder";
import { CREATE_QRIS_TRANSACTION } from "src/graphql/mutation/CreateQrisTransaction";
import { TransactionServices } from "src/service/TransactionServices";
import { ErrorServices } from "src/service/ErrorServices";
import { UserContext } from "src/context/UserContext";
import { rupiahFormat } from "src/helper/rupiahFormat";

export function PaymentSection({
  tableNumber,
  myRef,
  action,
  typeService,
  cart,
  handleCancel,
  handlePrint,
}) {
  const transactionServices = new TransactionServices();
  const errorServices = new ErrorServices();

  const { user } = useContext(UserContext);

  const [qrCode, setQrCode] = useState(null);
  const [dataOrder, setDataOrder] = useState(null);
  const [time, setTime] = useState(200);

  const [buttonclick, setButtonClick] = useState(false);

  const [
    createOrder,
    {
      loading: loadingCreateOrder,
      error: errorCreateOrder,
      data: dataCreateOrder,
    },
  ] = useMutation(CREATE_ORDER);

  const [
    createQrisTransaction,
    {
      loading: loadingCreateQrisTransaction,
      error: errorCreateQrisTransaction,
      data: dataCreateQrisTransaction,
    },
  ] = useMutation(CREATE_QRIS_TRANSACTION);

  async function handlePayment() {
    const tempOrder = cart.map((item) => {
      return { productId: item.id, quantity: item.amount, notes: item.notes };
    });

    const resCreateOrder = await createOrder({
      variables: {
        input: {
          orderInput: tempOrder,
          orderType:
            typeService == "dine-in"
              ? "DINE_IN"
              : typeService == "take-away"
              ? "TAKE_OUT"
              : "DINE_IN",
          tableNumber: tableNumber,
          customerPhone: user?.phone || null,
        },
      },
    });

    if (loadingCreateOrder == false && errorCreateOrder == undefined) {
      setDataOrder(resCreateOrder.data.createOrderCustomer.orderCustomer);

      const resPaymentQris = await handlePaymentQris(
        resCreateOrder.data.createOrderCustomer.orderCustomer
      );
      return resPaymentQris;
    } else {
      return false;
    }
  }

  async function handlePaymentQris(resOrder) {
    try {
      const res = await createQrisTransaction({
        variables: {
          input: {
            orderCustomerId: resOrder.id,
          },
        },
      });

      if (
        loadingCreateQrisTransaction == false &&
        errorCreateQrisTransaction == undefined
      ) {
        setQrCode(res.data.createQrisTransaction.transaction.url);

        handlePrint({
          orderId: res.data.createQrisTransaction.transaction.id,
        });

        const resMidtrans = await handleWaiting({
          id: res.data.createQrisTransaction.transaction.id,
          url: res.data.createQrisTransaction.transaction.url,
          resOrder: resOrder,
        });
        return resMidtrans;
      } else {
        return false;
      }
    } catch (error) {
      errorServices.Create({
        message: error.message,
      });

      setTimeout(() => {
        handlePayment();
      }, 3000);
    }
  }

  async function handleWaiting({ id, url, resOrder }) {
    const res = await transactionServices.isTransactionSettled({ id });

    if (res?.data?.isTransactionSettled == true) {
      action(resOrder);
    } else {
      setTimeout(() => {
        handleWaiting({ id: id, url: url, resOrder: resOrder });
      }, 2000);
    }
  }

  return (
    <dialog ref={myRef} className="bg-transparent scale-[1.75]">
      <div
        className={`h-full w-[450px] flex flex-col items-center justify-center gap-8 scale-75 ${
          buttonclick ? "hidden" : "block"
        }`}
      >
        <button
          onClick={async () => {
            setButtonClick(true);
            const res = await handlePayment();

            // dedcrease time for each second
            let tempTime = time;
            const interval = setInterval(() => {
              tempTime -= 1;
              setTime(tempTime);
            }, 1000);

            setTimeout(() => {
              window.location.reload();
            }, 200000);
          }}
          className={`text-[40px] font-bold hover:bg-secondary hover:text-white w-full py-6 rounded-2xl shadow-xl bg-main text-white `}
        >
          QRIS
        </button>
      </div>
      {qrCode ? (
        <div className="bg-white shadow-xl flex flex-col justify-center items-center rounded-lg px-1 py-5 w-[250px] mx-auto">
          <h2 className="f-h2 text-center text-main">QRIS</h2>
          <p className="text-center mt-2 text-[12px]">
            Pindai QRIS dan tunggu beberapa saat ...{" "}
          </p>
          <p className="text-orange-700 font-semibold mt-2">
            expired in{" "}
            {Math.floor(time / 60) < 10
              ? "0" + Math.floor(time / 60)
              : Math.floor(time / 60)}
            {" : "}
            {time % 60 < 10 ? "0" + (time % 60) : time % 60}
          </p>
          <img src={qrCode} className="w-full" />
          <h4 className="f-h4 text-center text-main">
            {rupiahFormat(dataOrder.totalAmount)}
          </h4>
        </div>
      ) : null}
    </dialog>
  );
}
