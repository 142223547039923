import imageThumb from "src/assets/icon/thumb.svg";

export function PaymentSuccessSection({ myRef }) {
  return (
    <dialog ref={myRef} className="bg-transparent">
      <div className="relative h-full w-full flex flex-col items-center justify-center gap-8">
        <div className="w-1/3">
          <img src={imageThumb} className="w-full" />
        </div>
        <div className="my-2">
          <h2 className="text-[48px] font-bold">Pembayaran Berhasil</h2>
        </div>
      </div>
    </dialog>
  );
}
